import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../../utilities/http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient, private httpHelper: HttpHelperService) { }

  getFeatureAccess(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set('stylistId',paramsReq.stylistId)
    const apiUrl = this.httpHelper.getSubApiUrl(`api/Features/GetFeaturesPermissionByStylistId`);
    return this.http.get(apiUrl, { params: params });
  }
}
