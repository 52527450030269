import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserBusinessService } from 'src/app/core/business/user/user-business.service';
import { PreferenceInput } from 'src/app/core/models/user/preference-input';
import { User } from 'src/app/core/models/user/user';
import { UserInput } from 'src/app/core/models/user/user-input';
import { UserDTO } from 'src/app/domain/user/user.dto';

@Injectable({
  providedIn: 'root'
})
export class UserFacadeService {

  constructor(private userBusinessService: UserBusinessService) { }

  getUser(id: number): Observable<User> {
    return this.userBusinessService.getUser(id);
  }
  getCustomerById(id: number): Observable<any> {
    return this.userBusinessService.getCustomerById(id);
  }

  getStylistName(id: number): Observable<any> {
    return this.userBusinessService.getStylistName(id);
  }

  updateUser(data: UserInput): Observable<UserInput> {
    return this.userBusinessService.updateUser(data);
  }

  updateDeletedSty(data: any): Observable<any> {
    return this.userBusinessService.updateDeletedSty(data);
  }

  verifyEmailFirebase(authId: string) {
    return this.userBusinessService.verifyEmailFirebase(authId);
  }
  getUserByAuth(paramsReq: any) {
    return this.userBusinessService.getUserByAuth(paramsReq);
  }

  userDetailsByAuth(data: any) {
    return this.userBusinessService.userDetailsByAuth(data);
  }

  getPreference(data: PreferenceInput) {
    return this.userBusinessService.getPreference(data);
  }

  getCustomerName(paramsReq: object) {
    return this.userBusinessService.getCustomerName(paramsReq);
  }
}
