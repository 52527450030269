import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { AuthFacadeService } from 'src/app/facades/auth/auth-facade.service';
import { UserFacadeService } from 'src/app/facades/user/user-facade.service';
import { LogoutComponent } from '../../auth-container/logout/logout.component';
import { SharedService } from 'src/app/core/services/shared/shared.service';
import { Location } from "@angular/common"
import { SubscriptionFacadeService } from 'src/app/facades/subscription/subscription-facade.service';
import { UserFeedbackState } from 'src/app/core/utilities/user-feedback-state/user-feedback-state';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent implements OnInit {

  pageTitle!: string;
  selectedIndex: number = 0;
  userId: number;
  profileImage: string = '';
  imgPic: boolean = false;
  showLegend: boolean = false;
  changeIndex: number;
  hiddenTitle: boolean = true;
  @ViewChild(MatMenuTrigger) legends: MatMenuTrigger;
  userFeedback = new UserFeedbackState();
  legendTitle: any;
  intervalId: NodeJS.Timer;
  constructor(
    private location: Location,
    private router: Router,
    private authFacadeService: AuthFacadeService,
    private activatedRoute: ActivatedRoute,
    private userFacadeService: UserFacadeService,
    private bottom: MatBottomSheet,
    private sharedService: SharedService,
    private subscriptionFacade: SubscriptionFacadeService,
    private cdr: ChangeDetectorRef) {
    this.userId = Number(localStorage.getItem('stylistId'));
    this.setPageTitle();
    this.getStylistDetails();
    this.getFeatureAccess();
    // Call the API every 20 minutes
    this.intervalId = setInterval(() => {
    this.getFeatureAccess();
    }, 1200000); // 20 minutes in milliseconds
  }

  getFeatureAccess() {
    this.userFeedback.startLoading();
    try {
      let data = {
        stylistId: this.userId,
      };
      this.subscriptionFacade.getFeatureAccess(data).subscribe({
        next: (res: any) => {
          localStorage.setItem("isCalendarAccess", res.isCalendarAccess);
          localStorage.setItem("isDoubleBookingAccess", res.isDoubleBookingAccess);
          localStorage.setItem("isRecurringAccess", res.isRecurringAccess);
          localStorage.setItem("isReportsAccess", res.isReportsAccess);
          localStorage.setItem('isEmailNotification', res.isEmailNotification);
          localStorage.setItem('isSMSNotification', res.isSMSNotification);
          //localStorage.setItem('locationLimitReached', res.locationLimitReached);
          localStorage.setItem("notesAccess", res.notesAccess);
          localStorage.setItem("onlineBookAccess", res.onlineBookAccess);
          localStorage.setItem("subscriptionExpired", res.subscriptionExpired);
        },
        error: (error) => {
          //this.showError(error);
          this.userFeedback.stopLoading();
        },
      });
    } catch (error) {
      //this.showError(error);
      this.userFeedback.stopLoading();
    }
  }


  getStylistDetails() {
    try {
      this.userFacadeService.getUser(this.userId).subscribe({
        next: (response: any) => {
          this.profileImage = response.images;
          localStorage.setItem('recordStatus', response.recordStatus);
          localStorage.setItem('locationId', response.locationId);
          if (this.profileImage == '' || this.profileImage == null) {
            this.imgPic = false;
          } else {
            this.imgPic = true;
          }
        },
        error: (error) => {
          this.imgPic = false;
        }
      });
    } catch (error: any) {
      this.imgPic = false;
    }
  }

  setPageTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let parentRoute = this.activatedRoute.firstChild;
        let route = this.activatedRoute.firstChild?.firstChild;
        parentRoute.data.subscribe((dat) => {
          const parentPage = dat["pageTitle"];
          this.pageTitle = parentPage;
          route.data.subscribe((d) => {
            if (parentPage == d["pageTitle"]) {
              this.hiddenTitle = true;
            } else {
              this.hiddenTitle = false;
            }
          });
        })
        // if(this.activatedRoute.firstChild.data._value.pageTitle)
        if (route?.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route: any) => route.data)
    ).subscribe((data: any) => {
      let findIndex: any = this.activatedRoute.routeConfig?.children;
      this.legendTitle = data.pageTitle;
      findIndex.forEach((e: any, i: any) => {      
        if (e.data?.pageTitle == this.pageTitle) {
          this.selectedIndex = i - 1;
        } else {
          return;
        }
      });
    })
  }
  ngOnInit(): void {
    this.sharedService.getUpdateObservable().subscribe(() => {
      this.getStylistDetails();
    });
  }

  breadcrumbClick(pageTitle) {
    switch (pageTitle) {
      case 'Appointments':
        this.selectedIndex = 0;
        this.router.navigate(['/appointments']);
        break;
      case 'Customers':
        this.selectedIndex = 1;
        this.router.navigate(['/customers']);
        break;
      case 'Services':
        this.selectedIndex = 2;
        this.router.navigate(['/services']);
        break;
      case 'Settings':
        this.selectedIndex = 3;
        this.router.navigate(['/settings']);
        break;
    }
  }
  onTabClick(event) {
    // Get the label of the clicked tab.
    const clickedTabLabel = event.target.textContent.trim();
    if (clickedTabLabel != '') {
      this.breadcrumbClick(clickedTabLabel);
    }
  }
  logout() {
    const config: MatBottomSheetConfig = {
      hasBackdrop: true, // Enable backdrop
    };

    const bottomSheetRef: MatBottomSheetRef = this.bottom.open(LogoutComponent, {
      panelClass: 'custom-style',
      ...config,
    });

    bottomSheetRef.afterOpened().subscribe(() => {
      // Listen for backdrop click event and close the bottom sheet
      const backdrop = document.querySelector('.cdk-overlay-backdrop');
      if (backdrop) {
        backdrop.addEventListener('click', () => {
          bottomSheetRef.dismiss();
        });
      }
    });
  }
  //for navigating to edit profile
  viewProfile() {
    this.router.navigate(['/settings']).then(() => window.location.reload());
  }
  //for navigating to sms report
  viewReport() {
    this.router.navigate(['/reports']).then(() => window.location.reload());
  }
  back() {
    this.location.back();
  }
}
