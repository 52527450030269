<div class="main-logout-container">
    <h2 class="logout-header">Logout ?</h2>
    <mat-divider></mat-divider>
    <p class="logout-title">Are you sure you want to logout?</p>
    <div class="logout-button-div">
        <button class="logout-cancel-button  equal-width-button" mat-flat-button color="accent"
            (click)="close()">Cancel</button>
        <button class="logout-button  equal-width-button" mat-flat-button color="primary" (click)="logout()">Yes,
            Logout</button>
    </div>
</div>