import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../../utilities/http-helper/http-helper.service';
import { User } from '../../models/user/user';
import { UserInput } from '../../models/user/user-input';
import { PreferenceInput } from '../../models/user/preference-input';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private httpHelper: HttpHelperService) { }

  getUser(id: number): Observable<User[]> {
    let params = new HttpParams()
      .set('Id', id);

    const apiUrl = this.httpHelper.getApiUrl(`/api/Stylists/GetStylistsById`);
    return this.http.get<User[]>(apiUrl, { params: params })
  }

  getCustomerById(id: number): Observable<any> {
    let params = new HttpParams()
      .set('Id', id);

    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/GetUserById`);
    return this.http.get<any>(apiUrl, { params: params })
  }

  getStylistName(id: number): Observable<any> {
    let params = new HttpParams()
      .set('ClientId', id);

    const apiUrl = this.httpHelper.getApiUrl(`/api/Stylists/GetStylistName`);
    return this.http.get<any>(apiUrl, { params: params })
  }

  updateUser(data: UserInput): Observable<UserInput> {
    const apiUrl = this.httpHelper.getApiUrl(`/api/Stylists/UpdateStylist`);
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.put<UserInput>(apiUrl, data, requestOptions);
  }

  updateDeletedSty(data: any): Observable<any>{
    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/UpdateDeleteUser`);
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.put<any>(apiUrl, data, requestOptions);
  }

  verifyEmailFirebase(authId: string): Observable<string> {
    let params = new HttpParams()
      .set('uid', authId);
    const requestOptions: Object = {
      responseType: 'text',
      params: params
    }
    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/verifyEmailFirebase`);

    return this.http.put<string>(apiUrl, '', requestOptions);
  }
  getUserByAuth(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set('AuthId', paramsReq.AuthId)
      .set('UserTypeId', paramsReq.UserTypeId)
    const apiUrl = this.httpHelper.getApiUrl(`api/Users/GetUserByAuthId`);
    return this.http.get(apiUrl, { params: params });
  }

  userDetailsByAuth(authId: string): Observable<string> {
    let params = new HttpParams()
      .set('AuthId', authId)
      .set('UserTypeId', "")

    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/GetUserByAuthId`);
    return this.http.get<any>(apiUrl, { params: params });
  }

  getPreference(data: PreferenceInput): Observable<PreferenceInput> {
    const apiUrl = this.httpHelper.getApiUrl(`/api/Stylists/UpdateApprovalStylist`);
    return this.http.put<PreferenceInput>(apiUrl, data);
  }

  getCustomerName(paramsReq: any): Observable<string> {
    let params = new HttpParams()
      .set('CustomerName', paramsReq.CustomerName)
      .set('StylistId', paramsReq.StylistId)
      .set('ClientId', paramsReq.ClientId)

    const apiUrl = this.httpHelper.getApiUrl(`/api/Users/GetCustomerNameList`);
    return this.http.get<any>(apiUrl, { params: params });
  }
}
