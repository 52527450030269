import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../../services/subscription/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionBusinessService {

  constructor( private subService: SubscriptionService) { }

  
  getFeatureAccess(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subService.getFeatureAccess(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: ((error) => {
          observer.error(error);
        })
      });
    }
    )
  }
}
