import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionBusinessService } from 'src/app/core/business/subscription/subscription-business.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionFacadeService {

  constructor( private subBusiness: SubscriptionBusinessService) { }

  getFeatureAccess(data): Observable<any> {
    return this.subBusiness.getFeatureAccess(data);
  }
}
